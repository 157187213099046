@import 'swiper/swiper.scss';
@import 'swiper/modules/pagination/pagination.scss';
@import 'swiper/modules/navigation/navigation.scss';

$themeColor: #999999;
$colors: (
  'white': #ffffff,
  'black': #000000,
);

:root {
  --swiper-theme-color: #{$themeColor};
  --swiper-navigation-size: 12px !important;
}
