@import-normalize;
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;700&display=swap');
@import 'swiper.scss';

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Maven Pro', sans-serif;
}

@-ms-viewport {
  width: device-width;
}

html,
body {
  &.no-scroll-x {
    overflow-x: hidden;
  }

  &.no-scroll {
    overflow-y: hidden;
    touch-action: none;
    -ms-touch-action: none;
  }
  &.in-iframe::-webkit-scrollbar {
    display: none; /*Chrome and opera*/
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  background-color: white;
  overscroll-behavior-y: none;
  overflow-x: hidden;
}

.grecaptcha-badge {
  opacity: 0;
  visibility: hidden;
}
